import {AxiosResponse} from 'axios'
import {
  AddNewMultimediasToGalleryRequest,
  CreateMultimediaSpaceRequest,
  IGetEditGaleriesPicturesBySpaceIdResponse,
  ISpaceEdit,
  ISpaceGroupEdit,
  UpdateMultimediaOrderRequest,
  UpdateMultimediaTitleRequest,
  UpdateMultimediaTypeNameRequest,
  UpdateMultimediaTypeOrderRequest,
} from './_models'
import {instance} from '../../../../../configs/axiosClient'
import {ID} from '../../../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL
const SPACES_URL = `${API_URL}Spaces`
const SPACES_GROUPS_URL = `${API_URL}SpacesGroups`
const MULTIMEDIA_URL = `${API_URL}Multimedia`
const MULTIMEDIA_TYPE_URL = `${API_URL}MultimediaType`

const getEditFormSpaceById = (query: ID): Promise<ISpaceEdit> => {
  return instance
    .get(`${SPACES_URL}/GetEditFormSpaceById/${query}`)
    .then((d: AxiosResponse<ISpaceEdit>) => {
      return d.data
    })
}

const getEditFormSpacesGroupById = (query: ID): Promise<ISpaceGroupEdit> => {
  return instance
    .get(`${SPACES_GROUPS_URL}/GetEditFormSpacesGroupById/${query}`)
    .then((d: AxiosResponse<ISpaceGroupEdit>) => {
      return d.data
    })
}
const editSpace = (body: ISpaceEdit): Promise<boolean> => {
  return instance.post(`${SPACES_URL}/UpdateSpace`, body).then((d: AxiosResponse<boolean>) => {
    return d.data
  })
}
const editSpaceGroup = (body: ISpaceGroupEdit): Promise<boolean> => {
  return instance
    .post(`${SPACES_GROUPS_URL}/UpdateSpaceGroup`, body)
    .then((d: AxiosResponse<boolean>) => {
      return d.data
    })
}

const GetEditGaleriesPicturesBySpaceId = (
  query: ID
): Promise<IGetEditGaleriesPicturesBySpaceIdResponse> => {
  return instance
    .get(`${MULTIMEDIA_URL}/GetEditGaleriesPicturesBySpaceId/${query}`)
    .then((d: AxiosResponse<IGetEditGaleriesPicturesBySpaceIdResponse>) => {
      return d.data
    })
}
const GetEditPortefolioPicturesBySpaceId = (
  query: ID
): Promise<IGetEditGaleriesPicturesBySpaceIdResponse> => {
  return instance
    .get(`${MULTIMEDIA_URL}/GetEditPortefolioPicturesBySpaceId/${query}`)
    .then((d: AxiosResponse<IGetEditGaleriesPicturesBySpaceIdResponse>) => {
      return d.data
    })
}
const AddNewMultimediasToGallery = (body: AddNewMultimediasToGalleryRequest): Promise<boolean> => {
  return instance
    .post(`${MULTIMEDIA_URL}/AddNewMultimediasToGallery/`, body)
    .then((d: AxiosResponse<boolean>) => {
      return d.data
    })
}
const UpdateMultimediasOrder = (body: Array<UpdateMultimediaOrderRequest>): Promise<boolean> => {
  return instance
    .post(`${MULTIMEDIA_URL}/UpdateMultimediasOrder/`, body)
    .then((d: AxiosResponse<boolean>) => {
      return d.data
    })
}
const UpdateMultimediasTitle = (body: UpdateMultimediaTitleRequest): Promise<boolean> => {
  return instance
    .post(`${MULTIMEDIA_URL}/UpdateMultimediasTitle/`, body)
    .then((d: AxiosResponse<boolean>) => {
      return d.data
    })
}
const DeleteMultimedia = (id: ID): Promise<boolean> => {
  return instance
    .delete(`${MULTIMEDIA_URL}/DeleteMultimediaById/${id}`)
    .then((d: AxiosResponse<boolean>) => {
      return d.data
    })
}
const CreateMultimediaTypeSpace = (body: CreateMultimediaSpaceRequest): Promise<boolean> => {
  return instance
    .post(`${MULTIMEDIA_TYPE_URL}/CreateMultimediaTypeSpace/`, body)
    .then((d: AxiosResponse<boolean>) => {
      return d.data
    })
}
const UpdateMultimediaTypeName = (body: UpdateMultimediaTypeNameRequest): Promise<boolean> => {
  return instance
    .post(`${MULTIMEDIA_TYPE_URL}/UpdateMultimediaTypeName/`, body)
    .then((d: AxiosResponse<boolean>) => {
      return d.data
    })
}
const UpdateMultimediaTypeOrder = (
  body: Array<UpdateMultimediaTypeOrderRequest>
): Promise<boolean> => {
  return instance
    .post(`${MULTIMEDIA_TYPE_URL}/UpdateMultimediaTypeOrder/`, body)
    .then((d: AxiosResponse<boolean>) => {
      return d.data
    })
}

const DeleteMultimediaType = (id: ID): Promise<boolean> => {
  return instance
    .delete(`${MULTIMEDIA_TYPE_URL}/DeleteMultimediaTypeById/${id}`)
    .then((d: AxiosResponse<boolean>) => {
      return d.data
    })
}

export {
  editSpace,
  editSpaceGroup,
  getEditFormSpaceById,
  getEditFormSpacesGroupById,
  GetEditGaleriesPicturesBySpaceId,
  GetEditPortefolioPicturesBySpaceId,
  AddNewMultimediasToGallery,
  UpdateMultimediasOrder,
  UpdateMultimediasTitle,
  DeleteMultimedia,
  CreateMultimediaTypeSpace,
  UpdateMultimediaTypeName,
  UpdateMultimediaTypeOrder,
  DeleteMultimediaType,
}
