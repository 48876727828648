export const AdapterConverNumberToCurrency = (value: number) => {
  if (value === 0) return '0,00 €'
  if (value) return value.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' €'
  return value.toString()
}

export const AdapterConverNumberToPercentage = (value: number) => {
  if (value === 0) return '0,00 %'
  if (value) return value.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' %'
  return value.toString()
}