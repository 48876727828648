import {ID} from '../../../../_metronic/helpers'

export interface AuthModel {
  api_token: string
  refreshToken?: string
}

export interface UserAddressModel {
  addressLine: string
  city: string
  state: string
  postCode: string
}

export interface RemoveClientFromPublicity {
  spaceGroupId:ID,
  clientId:ID
}

export interface UserCommunicationModel {
  email: boolean
  sms: boolean
  phone: boolean
}

export interface UserEmailSettingsModel {
  emailNotification?: boolean
  sendCopyToPersonalEmail?: boolean
  activityRelatesEmail?: {
    youHaveNewNotifications?: boolean
    youAreSentADirectMessage?: boolean
    someoneAddsYouAsAsAConnection?: boolean
    uponNewOrder?: boolean
    newMembershipApproval?: boolean
    memberRegistration?: boolean
  }
  updatesFromKeenthemes?: {
    newsAboutKeenthemesProductsAndFeatureUpdates?: boolean
    tipsOnGettingMoreOutOfKeen?: boolean
    thingsYouMissedSindeYouLastLoggedIntoKeen?: boolean
    newsAboutStartOnPartnerProductsAndOtherServices?: boolean
    tipsOnStartBusinessProducts?: boolean
  }
}

export interface UserSocialNetworksModel {
  linkedIn: string
  facebook: string
  twitter: string
  instagram: string
}

export interface UserModel {
  id: string
  password: string | undefined
  email: string
  firstName: string
  lastName: string
  language?: 'pt'
  auth?: AuthModel
  roles?: string[] | undefined
  userSpaces?: UserSpaces[] | undefined 
}
export type UserSpaces ={
  userId:ID;
  spaceGroupId:ID
  spaceId:ID
}

export type SelectList = {
  Text?: string
  Value?: string
  Selected?: boolean
  Disabled?: boolean
  Group?: SelectListGroup
}
export type SelectListGroup = {
  Name: string
}

export type Comunication = {
  Sms?: boolean
  SmsId?: ID
  SmsTitle?: string
  Email?: boolean
  EmailId?: string
  Notification?: boolean
  NotificationId?: string
  NotificationRedirectoPage?: number
  NotificationRedirectoPageId?: string
  Inquerito?: boolean
  InqueritoId?: string
}
export type Validity = {
  timeToUseType?: number
  timeToUseTime?: number
}

export type BirthDayType = {
  Type?: number
  Value1?: number
  Value2?: Date
}
export type NewsletterType = {
  Type?: number
  Value1?: Date
}
export type NumVisitsType = {
  Type?: number
  Value1?: number
  Value2?: number
}
export type ServExecInVisitsType = {
  Type?: number
  Value1?: ID
}
export type InqueritosType = {
  InqueritoId?: ID
  Name?: string
}

export enum EnumRoles {
  Administrador = 'Administrador',
  Marketing = 'Marketing',
  AdministradorEspacos = 'Administrador Espacos',
  Campanhas = 'Campanhas',
  Codes = 'Codes',
  Sms = 'Sms',
  Emails = 'Emails',
  Vouchers = 'Vouchers',
  FaturacaoGerente = 'Faturação Gerente',
  FaturacaoFuncionario = 'Faturação Funcionario',
  Notificacoes = 'Notificações',
  Estatisticas = 'Estatisticas',
}


export enum EnumRolesFront {
  Client = 'Client',
  Manager = 'Manager',
  Employee = 'Employee'
}
